import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { sakBaseUrl } from '../../../../utils/usefulFunctions';

function useQuickReplies () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
  });

  const queryUrl = sakBaseUrl('/Js/GetQuickRepliesExtensaoV2?') + queryParams.toString();

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60_000,
  });

  useEffect(() => {
    if (!Array.isArray(data?.data?.replies)) return;

    window.SAKshortcut = Object.fromEntries(data.data.replies.map(replies => ([ replies.shortcut, replies.texto ])));
  }, [data]);

  return {
    quickReplies: data?.data?.replies || [],
    isLoading,
    error,
    mutate
  };
}

async function deleteQuickReply (uuid) {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
    uuid
  });

  const queryUrl = sakBaseUrl('/Js/DeleteQuickReplyExtensaoV2?') + queryParams.toString();

  // const res = await fetch(queryUrl, { method: 'DELETE' });
  const res = await fetch(queryUrl, { method: 'POST' });
  const data = await res.json();

  if (data.status !== 'success')
    throw new Error('Failed to delete quick reply');

  return data;
}

async function postQuickReplies (replies) {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave
  });

  const queryUrl = sakBaseUrl('/Js/PostQuickReplyExtensaoV2?') + queryParams.toString();

  const res = await fetch(queryUrl, { method: 'POST', body: JSON.stringify(replies) });
  const data = await res.json();

  if (data.status === 'partial')
    void 0; //window.notifChat('-', 'warning');
  else if (data.status !== 'success')
    throw new Error('Failed to delete quick reply');

  return data;
}

function QuickReplyItem ({
  t,
  reply,
  handleShortcutChange,
  handleTextChange,
  handleDelete
}) {
  const [showBody, setShowBody] = useState(false);
  const bodyAreaRef = useRef(null);

  useEffect(() => {
    if (showBody && bodyAreaRef.current)
      bodyAreaRef.current.style.minHeight = bodyAreaRef.current.scrollHeight + 2 + 'px';
  }, [showBody]);

  return (
    <div
      key={'quick_reply_' + reply.uuid_shortcut}
      className="form-text-item shadow-none bg-transparent m-0 p-0 d-flex flex-column"
      style={{ gap: '.5rem' }}
    >
      <div className="d-flex justify-content-center align-items-baseline input-group-sm">
        <div
          className="rounded d-flex align-items-center w-100 chat-bg px-4"
          style={{ paddingTop: '.375rem', paddingBottom: '.375rem', border: '1px solid var(--scrollbar)' }}
        >
          <span>/</span>
          <input
            type="text"
            placeholder={t("Atalho")}
            className="border-0 pl-0 form-control-sm mb-0 flex-fill"
            value={reply.shortcut ?? ''}
            onChange={e => handleShortcutChange(reply.uuid_shortcut, e.target.value)}
            onClick={() => setShowBody(!showBody)}
          />

          {
            reply.uuid_shortcut && (
              <i
                className="fe fe-trash ml-4"
                style={{ cursor: 'pointer', fontSize: '1rem', color: 'inherit' }}
                onClick={() => handleDelete(reply.uuid_shortcut)}
              />
            )
          }
        </div>
      </div>

      {
        showBody && (
          <div
            className="mt-2"
          >
            <textarea
              ref={bodyAreaRef}
              placeholder={t("Texto completo")}
              className="form-control mb-0"
              value={reply.texto ?? ''}
              onChange={e => handleTextChange(reply.uuid_shortcut, e.target.value)}
            />
          </div>
        )
      }
    </div>
  );
}

export default function QuickRepliesTab () {
  const { t } = useTranslation();

  const { quickReplies, isLoading, error, mutate } = useQuickReplies();
  const [stagingReplies, setStagingReplies] = useState({});
  const [newReply, setNewReply] = useState({});

  const shortcutValidator = (element, shortcut, uuid) => {
    if (!element) return;

    if (!shortcut)
      return element.setCustomValidity(t('Atalho não pode ser vazio'));

    if (shortcut.length > 20)
      return element.setCustomValidity(t('Atalho muito longo'));

    if (quickReplies.some(reply => reply.uuid_shortcut !== uuid && reply.shortcut === shortcut))
      return element.setCustomValidity(t('Atalho já existe'));

    element.setCustomValidity('');
  }

  const handleShortcutChange = (uuid, shortcut) => {
    const stagingQuickReply = stagingReplies[uuid]
      || { ...quickReplies.find(reply => reply.uuid_shortcut === uuid) }
      || { uuid_shortcut: uuid };
    
    stagingQuickReply.shortcut = shortcut.trim().replace(/\s/g, '').toLowerCase();

    setStagingReplies({
      ...stagingReplies,
      [uuid]: stagingQuickReply
    });

    // TEMP
    shortcutValidator(document.getElementById('shortcut_' + uuid), shortcut, uuid);
  };

  const handleTextChange = (uuid, text) => {
    const stagingQuickReply = stagingReplies[uuid]
      || { ...quickReplies.find(reply => reply.uuid_shortcut === uuid) }
      || { uuid_shortcut: uuid };

    stagingQuickReply.texto = text;

    setStagingReplies({
      ...stagingReplies,
      [uuid]: stagingQuickReply
    });
  };

  const handleDiscardChanges = () => {
    setStagingReplies({});
  };

  const handleSaveChanges = async () => {
    const stagingRepliesArray = Object.values(stagingReplies);

    const quickRepliesCopy = [...quickReplies];
    for (const reply of stagingRepliesArray) {
      const replyIndex = quickRepliesCopy.findIndex(r => r.uuid_shortcut === reply.uuid_shortcut);
      if (replyIndex === -1) continue;

      quickRepliesCopy[replyIndex] = reply;
    }

    const mutatedData = { data: { replies: quickRepliesCopy } };
    const mutationOptions = {
      optimisticData: mutatedData,
      populateCache: true
    };

    const dataFunction = async () => {
      await postQuickReplies(stagingRepliesArray);
      return mutatedData;
    };

    await mutate(dataFunction, mutationOptions)
      .then(_ => {
        setStagingReplies({});
      })
      .catch(_err => {
        window.notifChat(t('Erro ao salvar respostas rápidas'), 'danger');
      });
  };

  const handleCreateNewReply = async () => {
    const newReplyCopy = { ...newReply };
    newReplyCopy.shortcut = newReplyCopy.shortcut.trim().replace(/\s/g, '').toLowerCase();

    const quickRepliesCopy = [...quickReplies];
    quickRepliesCopy.push(newReplyCopy);

    const mutatedData = { data: { replies: quickRepliesCopy } };
    const mutationOptions = {
      optimisticData: mutatedData,
      populateCache: true
    };

    const dataFunction = async () => {
      await postQuickReplies([newReplyCopy]);
      return mutatedData;
    };

    await mutate(dataFunction, mutationOptions)
      .then(_ => {
        setNewReply({});
      })
      .catch(_err => {
        window.notifChat(t('Erro ao adicionar resposta rápida'), 'danger');
      });
  };

  const handleDelete = async (uuid) => {
    if (!window.confirm(t('Tem certeza que deseja deletar esta resposta rápida?'))) return;

    const stagingRepliesCopy = { ...stagingReplies };
    delete stagingRepliesCopy[uuid];

    setStagingReplies(stagingRepliesCopy);

    const quickRepliesCopy = quickReplies.filter(r => r.uuid_shortcut !== uuid);

    const mutatedData = { data: { replies: quickRepliesCopy } };
    const mutationOptions = {
      optimisticData: mutatedData,
      populateCache: true
    };

    const dataFunction = async () => {
      await deleteQuickReply(uuid);
      return mutatedData;
    };

    await mutate(dataFunction, mutationOptions)
      .catch(_err => {
        window.notifChat(t('Erro ao deletar resposta rápida'), 'danger');
      });
  };

  if (isLoading) return (
    <div className='d-flex h-100 align-items-center justify-content-center'>
      <div class="spinner-border" role="status">
        <span class="sr-only">{t('Carregando...')}</span>
      </div>
    </div>
  );

  return (
    <div className="page-content">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="title-header mb-0">
              <h4 className="title">{t('Mensagens rápidas')}</h4>
              <p className="text">{t('Substitua seus textos por atalhos de palavras.')}</p>
            </div>
          </div>
        </div>
        <div className="row">
          {
            error && (
              <div className="col-12 mt-2">
                <div className="alert alert-danger mb-0 mt-2" role="alert">
                  <i className="fas fa-exclamation-triangle"></i> {t('Ocorreu um erro ao carregar as respostas rápidas.')}
                </div>
              </div>
            )
          }
          <div className="col-12">
            <div className="main-text">
              <div
                className="d-flex flex-column pt-4 pb-6"
                style={{ gap: '.5rem', borderBottom: '1px solid var(--scrollbar)' }}
              >
                <QuickReplyItem
                  t={t}
                  reply={newReply}
                  handleShortcutChange={(_, shortcut) => setNewReply({ ...newReply, shortcut })}
                  handleTextChange={(_, texto) => setNewReply({ ...newReply, texto })}
                />

                {
                  newReply.shortcut && newReply.texto && (
                    <div
                      className="mt-2 d-flex justify-content-between"
                      style={{ gap: '.5rem' }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary text-white flex-grow-1"
                        onClick={handleCreateNewReply}
                      >{t('Adicionar atalho')}</button>
      
                      <button
                        type="submit"
                        className="btn btn-outline-danger"
                        onClick={() => setNewReply({})}
                      >{t('Cancelar')}</button>
                    </div>
                  )
                }
              </div>
              {
                quickReplies.length > 0 && (
                  <div
                    className="d-flex flex-column pt-6"
                    style={{ gap: '.5rem' }}
                  >
                    {
                      quickReplies.map(reply => (
                        <QuickReplyItem
                          t={t}
                          key={reply.uuid_shortcut}
                          reply={ stagingReplies[reply.uuid_shortcut] || reply}
                          handleShortcutChange={handleShortcutChange}
                          handleTextChange={handleTextChange}
                          handleDelete={handleDelete}
                        />
                      ))
                    }
                    <div
                      className="mt-6 d-flex justify-content-between"
                      style={{ gap: '.5rem' }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary text-white flex-grow-1"
                        disabled={Object.keys(stagingReplies).length === 0}
                        onClick={handleSaveChanges}
                      >{t('Salvar alterações')}</button>

                      <button
                        type="submit"
                        className="btn btn-outline-danger"
                        disabled={Object.keys(stagingReplies).length === 0}
                        onClick={handleDiscardChanges}
                      >{t('Cancelar')}</button>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}