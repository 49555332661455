export function throttle(func, wait) {
  let lastCallTime = 0;
  return function (...args) {
    const now = Date.now();
    if (now - lastCallTime >= wait) {
      func.apply(this, args);
      lastCallTime = now;
    }
  };
}

export const getToken = () =>
  JSON.parse(localStorage.getItem('inicializacao'))['token'];

export const classnames = (...classes) => {
  return classes.map(className => {
    if (typeof className === 'object') {
      return Object.entries(className)
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(' ');
    }

    return className;
  }).filter(Boolean).join(' ');
};

export const sakBaseUrl = (...paths) => {
  if (paths[0].startsWith('/'))
    paths[0] = paths[0].slice(1);

  return 'https://app.sak.com.br/' + paths.join('/');
};
