import { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { classnames, sakBaseUrl } from '../../../../utils/usefulFunctions';
import Tooltip from '../../../Tooltip';

const statusDescription = {
  Inativo: 'Oculto para clientes ou membros da equipe',
  Publicado: 'Disponível para todos e visível no FAQ',
  Rascunho: 'Disponível para todos via URL do artigo, não listado no FAQ',
  Restrito: 'Disponível somente para sua equipe'
}

function overwriteChatInput (text) {
  const chatInputEl = document.getElementById('chat-id-1-input');
  Object.getOwnPropertyDescriptor(chatInputEl, 'value').set.call(chatInputEl, text);
  chatInputEl.dispatchEvent(new Event('input', { bubbles: true }));
}

function copyToClipboard (text) {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };

  document.body.appendChild(el);
  el.select();

  document.execCommand('copy');
  document.body.removeChild(el);

  if (text)
    window.notifChat('Copiado com sucesso!', 'success');
}

function useFAQData () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
  });

  const queryUrl = sakBaseUrl('/Js/GetFAQExtensaoV2?' + queryParams.toString());

  const { data, error, isLoading } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 120_000
  });

  return {
    categories: data?.data?.categories || [],
    config: data?.data?.config || {},
    isLoading,
    error
  };
}

function FaqArticle ({ config, article, setSelectedArticle }) {
  const { t } = useTranslation();

  if (!article) return null;

  return (
    <div>
      <header className="extensao--header">
        <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="fas fa-bars"/>
        </button>
        <div className="dropdown-menu dropdown-menu-right ml-3 text-left">
          <a
            href={sakBaseUrl('/faqadm#artigo=' + article.id_artigo)}
            target="_blank"
            rel="noopener noreferrer"
            className="dropdown-item"
            type="button"
          >{t('Editar artigo')}</a>
          <a
            href={config.faq_url + 'faq/' + article.slug_artigo}
            target="_blank"
            rel="noopener noreferrer"
            className="dropdown-item"
            type="button"
          >{t('Visualizar artigo')}</a>
          <button
            onClick={() => overwriteChatInput(config.faq_url + 'faq/' + article.slug_artigo)}
            className="dropdown-item"
            type="button"
          >{t('Enviar link do artigo')}</button>
          <button
            onClick={() => copyToClipboard(config.faq_url + 'faq/' + article.slug_artigo)}
            className="dropdown-item"
            type="button"
          >{t('Copiar link do artivo')}</button>
          <button
            onClick={() => copyToClipboard(article.busca)}
            className="dropdown-item"
            type="button"
          >{t('Copiar texto do artigo')}</button>
        </div>
        <strong>FAQ</strong>
        <button onClick={() => setSelectedArticle(null)}>
          <i className="fas fa-times"/>
        </button>
      </header>

      <div
        className="page-content page-content-total"
      >
        <div className="content-single">
          <h1>{ article.nome }</h1>
          <p dangerouslySetInnerHTML={{ __html: article.descricao }} />
        </div>
      </div>
    </div>
  );
}

export default function FaqTab () {
  const { t } = useTranslation();

  const { categories, config, isLoading } = useFAQData();
  const [query, setQuery] = useState('');
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const scrollableDivRef = useRef(null);

  function searchFaq (categories, query) {
    try {
      const searchable = categories
        .map(c => c.artigos).flat();
  
      const matches = window.fuzzysort.go(query, searchable, { key: 'busca', limit: 0, threshold: -50_000, all: true })
        .map(m => m.obj);
  
      return matches || [];
    } catch { return []; }
  }

  const filteredCategories = useMemo(() => {
    if (!categories) return [];

    const categoriesToBeFiltered = (selectedCategory && !query)
      ? [selectedCategory] : categories;

    const filteredArticlesIds = searchFaq(categoriesToBeFiltered, query).map(a => a.id_artigo);
    let filteredCategories = categoriesToBeFiltered.map(category => ({
      ...category,
      artigos: category.artigos.filter(article => filteredArticlesIds.includes(article.id_artigo))
    }));

    filteredCategories = filteredCategories.filter(category => category.artigos.length > 0);

    return filteredCategories;
  }, [categories, selectedCategory, query]);

  function selectArticle (article) {
    setLastScrollTop(scrollableDivRef.current.scrollTop);
    setSelectedArticle(article);
  }

  useEffect(() => {
    if (query && selectedCategory)
      setSelectedCategory(null);
  }, [selectedCategory, query]);

  useEffect(() => {
    if (!selectedArticle && scrollableDivRef.current)
      scrollableDivRef.current.scrollTop = lastScrollTop;
  }, [selectedArticle, lastScrollTop]);

  if (isLoading) return (
    <div className='d-flex h-100 align-items-center justify-content-center'>
      <div class="spinner-border" role="status">
        <span class="sr-only">{t('Carregando...')}</span>
      </div>
    </div>
  );

  if (selectedArticle) return (
    <FaqArticle config={config} article={selectedArticle} setSelectedArticle={setSelectedArticle}/>
  );

  return (
    <div style={{ overflow: 'visible' }}>
      <section className="main-search bg-transparent">
        <div className="form-search d-flex align-items-center justiyf-content-center" style={{ borderRadius: '0.375rem', background: 'var(--secondary', flex: '1' }}>
          <span className="lupa ml-5"><i className="fal fa-search"></i></span>
          <input
            type="text"
            style={{ color: 'black' }}
            className="form-control input-search"
            placeholder="Buscar"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        </div>
      </section>

      <div className="row">
        <div className="col-12">
          <div className="title-header2 text-right border-none">
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t('Filtro')}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  className={classnames("dropdown-item", { active: !selectedCategory })}
                  onClick={() => setSelectedCategory(null)}
                >{t('Todos')}</button>
                {
                  categories.filter(category => category.artigos.length > 0).map(category => (
                    <button
                      key={'faq_filter_' + category.id_categoria}
                      className={classnames("dropdown-item", { active: selectedCategory?.id_categoria === category.id_categoria })}
                      onClick={() => setSelectedCategory(category)}
                    >{ category.nome }</button>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content-scroll" ref={scrollableDivRef}>
        <div className="container">
          {
            filteredCategories.map(category => (
              <div
                key={'faq_category_' + category.id_categoria}
                className="lista-artigos"
              >
                <h5>{ category.nome }</h5>
                <div className="text-center pt-3" data-category={ category.id_categoria }>
                  {
                    category.artigos.map(article => (
                      <div
                        key={'faq_article_' + article.id_artigo}
                        className="faq-item-artigo"
                        onClick={() => selectArticle(article)}
                      >
                        <div>
                          <div>
                            <span className="faq--title">
                              <div className="d-flex align-items-center text-left">
                                <Tooltip text={t(article.status + ': ' + statusDescription[article.status])} position="left" >
                                  <img
                                    className="mr-3"
                                    src={sakBaseUrl('/assets/faq/status/' + article.status + '.svg')}
                                    alt={article.status}
                                  />
                                </Tooltip>
                                { article.nome }
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
