import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { sakBaseUrl } from '../../../../utils/usefulFunctions';

const arrayStatusColor =  {
  'pending': 'warning',
  'paid': 'success',
  'abandoned': 'gray',
  'order_cancelled': 'danger',
  'order_refunded': 'success'
};

const arrayStatus =  {
  'pending': 'Pendente',
  'paid': 'Pago',
  'abandoned': 'Abandonado',
  'order_cancelled': 'Cancelado',
  'order_refunded': 'Reembolso'
};

const gatewayImage = {
  'mercado_pago': 'image/gateways/mercadopago.svg',
  'mercado_pago_boleto': 'image/gateways/mercadopago.svg',
  'mercado_pago_cc': 'image/gateways/mercadopago.svg',
  'mercadopago': 'image/gateways/mercadopago.svg',
  'mercado_pago_transparente': 'image/gateways/mercadopago.svg',
  'pag_seguro': 'image/gateways/uol.svg',
  'pagseguro': 'image/gateways/uol.svg',
  'checkout_moip': 'image/gateways/moip.svg',
  'pagbrasil': 'image/gateways/pagbrasil.svg',
  'ebanx': 'image/gateways/ebanx.svg',
  'cartão_de_crédito_e_boleto_via': 'image/gateways/ebanx.svg',
  'cartão_de_crédito_e_boleto_via_ebanx': 'image/gateways/ebanx.svg',
  'paypal': 'image/gateways/paypal.svg',
  'guru': 'image/gateways/guru.svg',
  'paghiper': 'image/gateways/paghiper_m.jpg',
  'cielo': 'image/gateways/cielo_m.jpg',
  'desconhecido': 'image/gateways/questionmark.svg',
  'xekoutme': 'image/gateways/xekoutme.svg',
  'cloudfox': 'image/gateways/cloudfox.svg',
  'hotmart': 'image/gateways/hotmart.svg',
  'yampi': 'image/gateways/yampi.svg',
  'appmax': 'image/gateways/appmax.svg',
  'appmax_cc': 'image/gateways/appmax.svg',
  'appmax_boleto': 'image/gateways/appmax.svg',
  'uol': 'image/gateways/uol.svg',
  'kiwi': 'image/gateways/kiwi.svg',
};

const paymentMethodImage = {
  'boleto': 'image/gateways/boleto.svg',
  'credit_card': 'image/gateways/credicard.svg',
  'desconhecido': 'image/gateways/desconhecido.svg',
  'paypal': 'image/gateways/paypal.svg'
};

const rastreioNetAttribs = {
  'trackTransportadora': 'Código Transportadora',
  'trackPadrao': 'Código Rastreio',
  'transportadora': 'Transportadora',
  'dataPostagem': 'Data Postagem',
  'dataEntrega': 'Data Entrega',
  'numEmailEnviados': 'E-mails Enviados',
  'ultimoStatus': 'Último Status',
  'badge': 'Status'
}

export function useMensagensExtensao () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave
  });

  if (window.sakChatIsMeta)
    queryParams.append('official_only', 1);

  const queryUrl = sakBaseUrl('/Js/GetMensagensExtensaoV2?') + queryParams.toString();

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60_000
  });

  return {
    data: data?.data || {},
    isLoading,
    error,
    mutate
  };
}

export function enviarMensagemExtensao (msgId, orderId) {
  const formParams = new FormData();

  formParams.append('tokenchat', window.sakChatToken);
  formParams.append('chave_empreender', window.sakChatChave);
  formParams.append('wapp_id', window.sakChatLoja);
  formParams.append('chat_id', window.sakChatContato);
  formParams.append('msg_id', msgId);
  formParams.append('order_id', orderId);

  fetch(sakBaseUrl('/Js/EnviarMensagemExtensaoV2'), {
    method: 'POST',
    body: formParams
  })
    .then(res => res.json())
    .then(data => {
      if (data.status !== 'success')
        return window.notifChat((
          <>
            <strong>Erro ao enviar mensagem</strong>
            <br/>
            <small>{ data.message }</small>
          </>
        ), 'error');

      if (data.data.action === 'send')
        return window.notifChat('Enviando mensagem', '');

      const messageInputEl = document.getElementById('chat-id-1-input');
      if (!messageInputEl) return;

      const valueSetter = Object.getOwnPropertyDescriptor(messageInputEl.constructor.prototype, 'value').set;
      valueSetter.call(messageInputEl, data.data.message);
      messageInputEl.dispatchEvent(new Event('input', { bubbles: true }));
      messageInputEl.focus();
    })
    .catch(_err => {
      window.notifChat((
        <>
          <strong>Erro ao enviar mensagem</strong>
          <br/>
          <small>{ _err }</small>
        </>
      ), 'error');
    });
}

function timestampToString (timestamp) {
  return new Date(parseInt(timestamp) * 1e3).toLocaleString();
}

function copyToClipBoard (text) {
  const el = document.createElement('textarea');
  el.value = text;
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export default function OrdersTab ({
  orders,
  rastreioNet
}) {
  const { t } = useTranslation();

  const [orderIndex, serOrderIndex] = useState(0);
  const [previewMsg, setPreviewMsg] = useState(null);
  const { data: messages_data, isLoading: messages_isLoading } = useMensagensExtensao();

  useEffect(() => {
    if (orderIndex > 0 && orderIndex > orders.length - 1)
      serOrderIndex(0);
  }, [orders, orderIndex]);

  const order = orders?.[orderIndex];
  const trackingInfo = rastreioNet?.[order?.orderid]?.[order?.tracking];

  if (!order) {
    return (
      <div>
        <h6>{ t('Nenhum pedido encontrado') }</h6>
      </div>
    );
  }

  return (
    <>
      {
        orders?.length > 1 && (
          <div className="main-nav-items">
            <ul
              className="nav nav-tabs SFnav"
              role="tablist"
            >
              {
                orders.map((order, index) => (
                  <li
                    className="nav-item"
                    key={ 'order_nav_' + (order.id ?? index) }
                  >
                    <button
                      className={"nav-link taborder" + (index === orderIndex ? ' active' : '')}
                      onClick={() => serOrderIndex(index)}
                      role="tab"
                      aria-controls={ order.order_name || '?' }
                      aria-selected={ orderIndex === index }
                    >
                      { order.order_name || '?' }
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }

      <>
        <div className="row mt-3">
          <div className="col-12">
            <div className="order_section">
              <h4 className="sak--ext-title d-inline-flex">
                <i className="fe fe-message-square mr-3"/> { t('Mensagens rápidas') }
              </h4>
            </div>
            <div className="d-flex mt-4 px-3" style={{ flexWrap: 'wrap', gap: '.5rem', alignItems: 'center' }}>
              {
                messages_isLoading
                  ? (
                    <span>
                      <div class="spinner-border" role="status">
                        <span class="sr-only">{t('Carregando...')}</span>
                      </div>
                    </span>)
                  : (
                    <>
                      {
                        messages_data?.mensagens?.map(msg => (
                          <span className='sIconWhats'>
                            <span
                              className="whatsLink"
                              onMouseOver={() => setPreviewMsg({ body: msg.texto, title: msg.nome })}
                              onMouseOut={() => setPreviewMsg(null)}
                              onClick={() => enviarMensagemExtensao(msg.id, order.id)}
                            >
                              <i className='fab fa-whatsapp'/>
                            </span>
                          </span>
                        ))
                      }
                      <a
                        className='sIconWhats small d-flex'
                        href={sakBaseUrl("/mensagens#tab-extensao")}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ opacity: '.75' }}
                      >
                        <i className='fe fe-plus-circle'/>
                      </a>
                    </>
                  )
              }
            </div>
          </div>
        </div>      
          <div className="row mt-4">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between order_section">
                <h4 className="sak--ext-title mb-0">
                  <i className="fe fe-tag mr-3"/> { t('Detalhes do pedido') }
                </h4>
              </div>
            </div>

            <div className="col-12">
              <div className="customers-content">
                <div className="sak--perfil-info pl-3" style={{ overflowWrap: 'anywhere' }}>
                  <ul>
                    <li>
                      <strong>{ t('Pedido') }</strong>
                      <span>
                        {
                          order.link_pedido
                            ? (
                              <a
                                className="sak--ext-sub-title"
                                href={order.link_pedido}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span dangerouslySetInnerHTML={{ __html: order.plataforma}} style={{ textTransform: 'capitalize' }} />: { order.order_name }
                              </a>
                            )
                            : (
                              <span className="sak--ext-title" style={{ display: 'block' }}>
                                <span dangerouslySetInnerHTML={{ __html: order.plataforma}} style={{ textTransform: 'capitalize' }} />: { order.order_name }
                              </span>
                            )
                        }
                      </span>
                    </li>
                    <li>
                      <strong>{ t('Nome') }</strong>
                      <span>{ order.customer?.name }</span>
                    </li>
                    <li>
                      <strong>{ t('Data') }</strong>
                      <span>{ timestampToString(order.created_at) }</span>
                    </li>
                    <li>
                      <strong>{ t('Atualização') }</strong>
                      <span>{ timestampToString(order.updated_at) }</span>
                    </li>
                    <li>
                      <strong>{ t('Telefone') }</strong>
                      <span>{ order.customer?.phone }</span>
                    </li>
                    <li>
                      <strong>{ t('E-mail') }</strong>
                      <span>{ order.customer?.email }</span>
                    </li>
                    <li>
                      <strong>{ t('Endereço') }</strong>
                      <span>{ order.customer?.formatted_address }</span>
                    </li>
                  </ul>
                </div>
      
                <div className="customers-item d-flex mt-4 px-3" style={{ gap: '.5rem', flexWrap: 'wrap' }}>
                  <h4 className="sak--ext-title mb-2">{ t('Status de pagamento') }</h4>
                  <span
                    className={"badge badge-pill badge-soft-" + arrayStatusColor[order.status_pagamento]}
                  >
                    { t(arrayStatus[order.status_pagamento]) }
                  </span>

                  {
                    order.checkout_url && order.status_pagamento === 'abandoned' && (
                      <a href={order.checkout_url} target="_blank" rel='noreferrer'>
                        <img src={sakBaseUrl('image/gateways/cart.svg')} width='38' alt='cart'/>
                      </a>
                    )
                  }

                  {
                    order.gateway && (
                      <span className='link-payments mr-1' title={order.gateway}>
                        <img
                          width='38'
                          className='logo-payments'
                          src={sakBaseUrl(gatewayImage[order.gateway] || gatewayImage['desconhecido'])}
                          alt={ t(order.gateway) }
                        />
                      </span>
                    )
                  }

                  {
                    order.forma_pagamento && (
                      order.forma_pagamento === 'boleto'
                        ? (
                          <a className='link-payments mr-1' href={order.link_boleto} target='_blank' rel='noreferrer'>
                            <img
                              width='38'
                              className='logo-payments'
                              src={sakBaseUrl(paymentMethodImage[order.forma_pagamento] || paymentMethodImage['desconhecido'])}
                              alt={ t(order.forma_pagamento) }
                            />
                            <i
                              className='fal fa-copy ml-3'
                              data-toggle='tooltip2'
                              data-placement='right'
                              title='Copiar'
                              onclick={_ => copyToClipBoard(order.link_boleto)}
                            />
                          </a>
                        )
                        : (
                          <span className='link-payments mr-1'>
                            <img
                              width='38'
                              className='logo-payments'
                              src={sakBaseUrl(paymentMethodImage[order.forma_pagamento] || paymentMethodImage['desconhecido'])}
                              alt={ t(order.forma_pagamento) }
                            />
                          </span>
                        )
                    )
                  }
                </div>
      
                <div className="customers-item products-list mt-5">
                  <div className="order_section mb-2">
                    <h4 className="sak--ext-title">
                      <i className="fe fe-package mr-3"/> { t('Produtos') }
                    </h4>
                  </div>
                  <ul className="ul-product px-3 mt-4">
                    {
                      order.line_items?.length > 0
                        ? order.line_items?.map((item, pIndex) => (
                          <li
                            className="li-product"
                            key={ 'product_' + (item.product_id ?? pIndex) }
                          >
                            <div className="li-product-info row align-items-center">
                              <div className="foto-prod">

                                {
                                  item.image_urls?.map((img, iIndex) => {
                                    return (
                                      <img
                                        className="mr-2 imgProd"
                                        key={ 'product_' + (item.product_id ?? pIndex) + '_' + iIndex }
                                        src={img}
                                        onError={(e) => e.target.src = sakBaseUrl('/image/sem-imagem.svg')}
                                        alt="produto_img"
                                      />
                                    )
                                  })
                                }

                              </div>

                              <div className="product-info detalhe-prod">
                                <h5 className="font-size-md mb-0">
                                  { item.name || item.title }
                                </h5>

                                {
                                  order.total_discount > 0 && (
                                    <strong>{ t('Desconto') }: { order.currency } { order.total_discount }</strong>
                                  )
                                }

                                {
                                  item.price > 0 && (
                                    <strong>
                                      { item.quantity > 0 && (item.quantity + ' un') }
                                      { order.currency }{ item.price }
                                    </strong>
                                  )
                                }

                                {
                                  item.variant_title && (
                                    <strong>{ t('Variante') }: { item.variant_title }</strong>
                                  )
                                }
          
                              </div>
          
                            </div>
                          </li>
                        ))
                        : (
                          <span>{ t('Nenhum produto associado a este pedido') }</span>
                        )
                    }
                  </ul>
                  <div className="sak--perfil-info px-3">
                    <ul>
                      <li>
                        <strong>{ t('Frete') }</strong>
                        {
                          order.total_tax >= 0
                            ? `${order.currency} ${order.total_tax}`
                            : t('Indisponível')
                        }
                      </li>
                      <li>
                        <strong>{ t('Total') }</strong>
                        { order.currency } { order.total_price }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="row mt-3 mb-4">
            <div className="col-12 order_section mb-2">
              {
                order.tracking
                  ? (
                    <a
                      className="sak--ext-title link d-inline-flex"
                      href={'https://rastreio.net/' + order.tracking.split(',')[0]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fe fe-truck mr-3"/> Rastreio.net
                    </a>
                  )
                  : (
                    <span
                      className="sak--ext-title link d-inline-flex"
                    >
                      <i className="fe fe-truck mr-3"/> Rastreio.net
                    </span>
                  )
              }
              
            </div>
      
            <div className="col-12 mt-4 px-3">
              <div className="rastreio-content">
                <div className="rastreio-item">
                  <div className="rastreio-info">
                    <div className="rastreio-number">
                      {
                        order.status_pagamento === 'paid' && order.tracking && trackingInfo
                          ? (
                            <div>
                              {
                                Object.values(trackingInfo).map((aName, aValue) => {
                                  if (!rastreioNetAttribs[aName]) return null;

                                  return (
                                    <small
                                      className="form-text text-muted"
                                    >
                                      <strong>{ t(rastreioNetAttribs[aName]) }:</strong> { aValue }
                                    </small>
                                  )
                                })
                              }
                            </div>
                          )
                          : (
                            <span>{ t('Sem informações de rastreio associadas a este pedido') }</span>
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            previewMsg && (
              <div id="simMsg" className="printCelular">
                <div className="contorno-cel">
                  <span></span>
                  <span></span>
                </div>
                <div className="header-cel">
                  <strong>{ previewMsg.title }</strong>
                </div>
                <div className="msg-cel">{ previewMsg.body }</div>
              </div>
            )
          }
      </>
    </>
  );
}