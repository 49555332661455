import React, {
  useState,
  useEffect
} from 'react';

import { useTranslation } from 'react-i18next';

import useSWR from 'swr';

import { formatPhoneNumber } from '../../../../utils/formatDados';
import { classnames, sakBaseUrl } from '../../../../utils/usefulFunctions';	
import useDadosSakStore from '../../../../stores/useDadosSakStore';

import ProfileThingie from './ProfileTab';
import OrdersThingie from './OrdersTab';
import TemplatesTab from './TemplatesTab';

function useDadosExtensao (query, search_by_phone = 0) {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
    search_by_phone,
    query
  });

  const queryUrl = query ? sakBaseUrl('/Js/GetDadosExtensaoV2?' + queryParams.toString()) : null;

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60_000
  });

  return {
    data: data?.data || {},
    isLoading,
    error,
    mutate
  };
}

export default function HomeTab() {
  const { t } = useTranslation();

  const [actualQuery, setActualQuery] = useState(null);
  const [query, setQuery] = useState('');

  const [searchByPhone, setSearchByPhone] = useState(1);

  const [clientData, setClientData] = useState([]);
  const [leadLists, setLeadLists] = useState([]);
  const [orders, setOrders] = useState([]);
  const [rastreioNet, setRastreioNet] = useState([]);

  const [warningMessage, setWarningMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState('profile');

  const { data, isLoading, error, mutate } = useDadosExtensao(actualQuery, searchByPhone);

  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const userLogado = useDadosSakStore((state) => state.userLogado);

  function handleQuerySubmit (event) {
    event.preventDefault();
    setSearchByPhone(1);
    setActualQuery(query || null);
  }

  async function saveClientInfo (index) {
    if (!clientData[index]) {
      setWarningMessage('Erro ao salvar dados');
      return;
    }

    clientData[index].buscaInicial = actualQuery || query || '';

    const mutatedData = { data: { ...data, client_info: clientData } };
    const mutationOptions = {
      optimisticData: mutatedData,
      populateCache: true
    };

    const dataFunction = async () => {
      const queryUrl = sakBaseUrl('/Js/PostClienteExtensaoV2');

      const formData = new FormData();
      formData.append('tokenchat', window.sakChatToken);
      formData.append('chave_empreender', window.sakChatChave);
      formData.append('data', JSON.stringify(clientData[index]));

      const res = await fetch(queryUrl, { method: 'POST', body: formData });
      const data = await res.json();

      if (data.status !== 'success')
        throw new Error(data.message || 'Erro ao salvar dados');

      mutatedData.data.client_info[index] = data.data[0];

      return mutatedData;
    };

    mutate(dataFunction, mutationOptions)
      .catch(err => {
        setWarningMessage(err.message || 'Erro ao salvar dados');
      });
  }

  function handleTabChange (event, tab) {
    event.preventDefault();
    setSelectedTab(tab);
  }

  useEffect(() => {
    let formatted = '+' + contatoAtivo.chatid.split('@')[0].toString();
    formatted = formatPhoneNumber(formatted);

    setQuery(formatted);
    setSearchByPhone(1);
    setActualQuery(formatted || null);

  // eslint-disable-next-line
  }, [contatoAtivo?.chatid, userLogado]);

  useEffect(() => {
    if (isLoading) return;

    setClientData(data?.client_info || []);
    setLeadLists(data?.lead_lists || []);
    setOrders(data?.orders || []);
    setRastreioNet(data?.rastreio_net || []);

  }, [data, isLoading]);

  useEffect(() => {
    if (warningMessage) {
      setTimeout(() => {
        setWarningMessage('');
      }, 5000);
    }
  }, [warningMessage]);

  function TabContent () {
    if (selectedTab === 'profile')
      return (
        <div>
          <ProfileThingie
            client_info={clientData}
            saveClientInfo={saveClientInfo}
            setClientData={setClientData}
            leadLists={leadLists}
            warningMessage={warningMessage}
          />
        </div>
      );

    if (selectedTab === 'templates')
      return (
        <div>
          <TemplatesTab />
        </div>
      );

    return (
      <div>
        <OrdersThingie
          orders={orders}
          rastreioNet={rastreioNet}
        />
      </div>
    );
  }

  if (isLoading) return (
    <div className='d-flex h-100 align-items-center justify-content-center'>
      <div class="spinner-border" role="status">
        <span class="sr-only">{t('Carregando...')}</span>
      </div>
    </div>
  );

  if (error) return (
    <div className='d-flex h-100 align-items-center justify-content-center'>
      <div class="alert alert-danger" role="alert">
        {t('Erro ao carregar dados.')}
      </div>
    </div>
  );

  return (
    <>
      <section class="main-search bg-transparent">
        <form onSubmit={handleQuerySubmit} >
          <div class="form-search d-flex align-items-center justiyf-content-center w-100" style={{ borderRadius: '0.375rem', flex: '1' }}>
            <span class="lupa ml-5">
              <i class="fal fa-search"/>
            </span>
            <input
              type="text"
              name="query"
              value={query}
              onChange={e => setQuery(e.target.value)}
              placeholder={t("Buscar pedido por nº, nome, tel ou email.")}
              className="form-control input-search text-dark"
            />
          </div>
        </form>
      </section>

      {
        data?.highlighted_product && (
          <div style={{ padding: '0 10px 10px 10px' }}>
            <div style={{
              color: '#49c18f',
              background: 'rgba(73, 193, 143, 0.15)',
              borderRadius: '3px',
              border: 'none',
              padding: '10px 16px',
              fontSize: '12px'
            }}>
              {data.highlighted_product.name} (R${ data.highlighted_product.price })
            </div>
          </div>
        )
      }

      <div className="info-nav-items saknavapps">
        <ul className="nav nav-tabs justify-content-start bg-transparent" role="tablist">
          <li className="nav-item">
            <button
              className={classnames("nav-link  bg-transparent rounded-0 sak-link-nav-app", { active: selectedTab === 'profile' })}
              onClick={e => handleTabChange(e, 'profile')}
              title="Perfil"
              role="tab"
              aria-controls="home"
              aria-selected={selectedTab === 'profile'}
            >
              <i class="fal fa-address-card"/> { t('Perfil') }
            </button>
          </li>

          <li class="nav-item">
            <button
              class={classnames("nav-link bg-transparent rounded-0 sak-link-nav-app", { active: selectedTab === 'orders' })}
              onClick={e => handleTabChange(e, 'orders')}
              title="Pedidos"
              role="tab"
              aria-controls="orders"
              aria-selected={selectedTab === 'orders'}
            >
              <i class="fal fa-shopping-bag"/> { t('Pedidos') }
            </button>
          </li>

          {
            window.sakChatIsMeta && (
              <li class="nav-item">
                <button
                  class={classnames("nav-link bg-transparent rounded-0 sak-link-nav-app", { active: selectedTab === 'templates' })}
                  onClick={e => handleTabChange(e, 'templates')}
                  title="Templates"
                  role="tab"
                  aria-controls="templates"
                  aria-selected={selectedTab === 'templates'}
                >
                  <i class="fab fa-whatsapp"/> { t('Templates') }
                </button>
              </li>
            )
          }
        </ul>
      </div>

      <div className='tab-content tab-info-items mt-1 pt-1'>
        { TabContent() }
      </div>
    </>
  );
}
